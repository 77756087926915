module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-transition-link@1.20.5_@babel+core@7.23.5_react-dom@16.14.0_react@16.14.0/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Manrope","Font Awesome 6 Pro","Font Awesome 6 Brands"],"urls":["/assets/css/fonts.css"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.12.1_gatsby@2.32.13_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vu Des Quartiers","short_name":"vdq","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/var/www/app/static/assets/favicon/favicon-96x96.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16bbfbbaaeb10529ec4c18470310887d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
