/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


//import './assets/scss/global.scss';

//FIX bug missing styles on page which are not loaded via Gatsby Router
///https://github.com/gatsbyjs/gatsby/issues/17676
import ReactDOM from 'react-dom';
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}